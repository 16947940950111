import React from "react"
import Layout from "../components/layout"
import { css } from "@emotion/core"
import Share from "../components/share"
import useSiteMetadata from "../hooks/use-sitemetadata"

const Contact = ({ siteConfig }) => {
  const { title, twitterUrl, twitterHandle } = useSiteMetadata()

  return (
    <Layout>
      <div style={{ color: `black` }}>
        <div
          css={css`
            margin-right: 40px;
            margin-top: 20px;
            padding-right: 40px;
            width: 100%;
            @media (min-width: 600px) {
              width: 80%;
            }
          `}
        >
          <h1
            css={css`
              margin-bottom: 20px;
            `}
          >
            Contact Me
          </h1>
          <p>
            I'd like to hear from you! What programming topic or technology are you learning
            right now? Do you have a programming book or article that you'd
            recommend?
          </p>
          <p>
            Email me at:{" "}
            <a href="mailto: amy@amymhaddad.com">amy@amymhaddad.com</a>
          </p>
          <p>
            Follow me on Twitter:{" "}
            <a href="https://twitter.com/amymhaddad">@amymhaddad</a>
          </p>
          <Share
            title={title}
            twitterUrl={twitterUrl}
            twitterHandle={twitterHandle}
          />
        </div>
      </div>
    </Layout>
  )
}

export default Contact
