import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    TwitterShareButton,
} from 'react-share';
import { css } from '@emotion/core';



const Share = ({title, twitterUrl, twitterHandle, tags=[]}) => (
	<div className="post-social">
		<TwitterShareButton url={twitterUrl} className="button is-outlined is-rounded twitter" title={title} via={twitterHandle.split('@').join('')} hashtags={tags}
			css={css`
		
			margin: 0.5rem 0.25rem;
			color: #1da1f2;
			border-color: #1da1f2;
			&:hover {
				background-color: #1da1f2;
				border-color: #1da1f2;
				color: #fff;
			}
	
		`}
		>
			{/* <span className="icon">
				<FontAwesomeIcon icon={['fab', 'faTwitter']} />
			</span>
			<span className="text">Twitter</span> */}
		</TwitterShareButton>
	</div>
);

Share.defaultProps = {
	tags: [],
};

export default Share;